import { Link } from '@remix-run/react'
import clsx from 'clsx'
import { Collection } from '~common/generated/storefront-graphql'
import { formatPrice } from '~common/utils/format/priceFormat'

interface CollectionCardProps {
  compact?: boolean
  collection: Collection
  detailUrl: string
  className?: string
}

export function CollectionCard({
  compact,
  collection,
  detailUrl,
  className,
}: CollectionCardProps) {
  const isLowestPriceAvailable =
    collection.customFields && collection.customFields.pricingLowest

  return (
    <Link
      to={detailUrl}
      prefetch="intent"
      key={collection.id}
      className={clsx(
        'rounded-4xl relative shrink-0 overflow-hidden',
        {
          'w-full min-w-[200px] xl:w-auto': !compact,
          'w-[175px]': compact,
        },
        className,
      )}
    >
      <div className="overflow-hidden rounded-2xl bg-gray-50 object-cover object-center dark:bg-slate-800">
        {collection.featuredAsset?.preview ? (
          <img
            src={collection.featuredAsset?.preview + '?w=300&h=240'}
            className="aspect-[300/240] w-full min-w-[200px] rounded-2xl transition duration-300 ease-in-out hover:scale-110"
          />
        ) : null}
      </div>
      <div className="mt-3 flex w-full items-center justify-between">
        <p className="text-left text-xl font-bold text-black dark:text-slate-300">
          {collection.name}
        </p>
        {/* <p className="bg-dark-purple text-white py-1 px-2 rounded-md">
          10% OFF
        </p> */}
      </div>
      {isLowestPriceAvailable ? (
        <div
          className={clsx('mt-2 flex w-full items-start justify-between', {
            'flex-col': compact,
          })}
        >
          <p
            className={clsx('text-gray-500', {
              'text-lg': !compact,
              'text-xs': compact,
            })}
          >
            Starts from
          </p>
          <div className="flex w-auto flex-col items-end justify-start">
            {/* <p className="text-md font-bold line-through">
              <span className="text-gray-400 dark:text-slate-400">$ 150.00</span>
              <span className="text-green-400"> / monthly</span>
            </p> */}
            <p
              className={clsx({
                'text-lg font-bold': !compact,
                'text-xs font-medium': compact,
              })}
            >
              <span>
                {formatPrice(Number(collection.customFields?.pricingLowest))}
              </span>
              {collection.customFields?.pricingInterval ? (
                <span className="ml-1 text-green-600">
                  / {collection.customFields?.pricingInterval}
                </span>
              ) : null}
            </p>
          </div>
        </div>
      ) : null}
    </Link>
  )
}
